<template>
  <a-config-provider :locale="$i18n.locale === 'en' ? enUS : zhCN">
    <router-view v-if="haveConfig" />
  </a-config-provider>
</template>

<script>
import { ref, provide, reactive } from 'vue'
import enUS from 'ant-design-vue/es/locale/en_US'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { ConfigProvider } from 'ant-design-vue'
import { setTheme } from './styles/theme'
import { themes } from './styles/theme/themeData'
import instance from './api/axios'
import axios from './api/request.js'

export default {
  name: 'App',
  components: {
  },
  setup() {
    const haveConfig = ref(false)
    const ottApiUrl = reactive({
      development: window.location.origin.indexOf('localhost') !== -1 ? '/dev' : 'http://api.dev.msp.ottpayhk.cn',
      sandbox: 'http://api.test.msp.ottpayhk.cn',
      test2: 'http://api.test2.msp.ottpayhk.cn',
      fat: 'http://api.fat.msp.ottpayhk.cn',
      uat: 'https://api.uat.msp.ottpayhk.com',
      production: 'https://api.msp.ottpayhk.com'
    })
    const projectData = ref({
      saasMerNameZh: 'OTTPAY HK',
      saasMerNameEn: 'OTTPAY HK',
      email: 'support@ottpayhk.com',
      website: 'www.ottpayhk.com',
      copyRight: '@Copyright 2022 OTT PAY HK Limited. All rights reserved 沪ICP备 19016008号-1',
      address: 'Company registered in HongKong with Money Services Operator License number:19-12-02936',
      themeKey: 'default',
      themeColor: '#1890FF',
      electronicReceipt: true,
      electronicReceiptBatch: true,
      openReceipt: true,
      supply: false,
      suffix: '',
      faviconList: 'ottpayhk.ico',
      darkLogoList: 'login-logo-ott.png',
      lightLogoList: 'menu-logo-ott.png',
      bgList: 'login-banner-ott.png',
      apiUrl: ottApiUrl[process.env.NODE_ENV],
      reconReceipt: true
    })
    provide('$themeData', projectData)

    const setFavicon = (url) => {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = url
      document.getElementsByTagName('head')[0].appendChild(link)
    }

    const init = async () => {
      if (process.env.VUE_APP_SAASNAME !== 'OTT') {
        const baseURL = process.env.VUE_APP_SAAS_CONFIGURL

        const { data } = await instance.post(`${baseURL}/saas/getSaasMerConfig`)
        if (data.code === 'S00000') {
          projectData.value = {
            saasMerNameZh: data.data.saasMerNameZh,
            saasMerNameEn: data.data.saasMerNameEn,
            electronicReceiptBatch: false,
            ...JSON.parse(data.data.frontJson)
          }
        } else {
          return false
        }
      }
      // setTheme(projectData.value.themeKey)
      setTheme(themes[process.env.VUE_APP_SAASNAME] ? process.env.VUE_APP_SAASNAME : 'default')
      setFavicon(projectData.value.faviconList)

      ConfigProvider.config({
        // theme: themes[projectData.value.themeKey] || themes[process.env.VUE_APP_SAASNAME] || themes['default']
        theme: themes[process.env.VUE_APP_SAASNAME] || themes['default']
      })

      axios.defaults.baseURL = projectData.value.apiUrl
      haveConfig.value = true
    }

    init()

    return {
      enUS,
      zhCN,
      haveConfig
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}
</style>
